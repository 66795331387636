import React from "react"
import { withPrefix } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactForm from "components/ContactForm"
// import Popup from "components/Popup"

import { Apartments, Nav, News, CallToAction } from "page_components/investment"

import {
  Hero,
  Gallery,
  About,
  Location,
  BanerBackground,
  AboutSecond,
  AboutThird,
  BanerConvenients,
} from "page_components/investment/ultradom"

const Investment = ({ location }) => {
  const title = "Ultradom"

  return (
    <Layout
      location={location}
      seo={{
        title: "Ultradom",
        image: withPrefix("/ultradom-og-image.jpg"),
      }}
      rmGlobalPopup
    >
      <Breadcrumbs
        title={title}
        parent={{ label: "Radom", url: "/en/flats/radom" }}
        subParent={{ label: "Flats", url: "/en/flats" }}
      />
      <Hero title={title} />
      <Nav title={title} investment="Ultradom" investmentRaw="ultradom" />
      <Apartments investment="Ultradom" investmentRaw="ultradom" />
      <About title={title} />
      <BanerBackground />
      <AboutSecond />
      <Location />
      <AboutThird />
      <BanerConvenients />
      <Gallery />
      <News investment="ultradom" city="Radom" />
      <CallToAction />
      <ContactForm
        formType="sale"
        investment="Ultradom"
        investmentRaw="ultradom"
      />
      {/* <Popup type="radom" /> */}
    </Layout>
  )
}

export default Investment
